<sm-dialog-template [header]="'Select compared '+ data.entityType + 's'">
  <div class="select-experiments-container light-theme" (click)="closeSearch()">
    <div class="search-experiment-for-compare d-flex justify-content-between mb-3">
      <mat-form-field appearance="outline" class="no-bottom" style="width: 420px" smClickStopPropagation>
        <input
          #searchExperiments
          autofocus
          matInput
          name="search-experiments"
          autocomplete="off"
          smClickStopPropagation
          placeholder="Start typing {{data.entityType}} name or ID to add to your comparison"
          (input)="searchTermChanged(searchExperiments.value)"
        >
      </mat-form-field>
      <div class="d-flex-center">
        <mat-slide-toggle
          class="me-2"
          [checked]="showArchived$ | async"
          (change)="showArchived($event)"
        >Include Archived</mat-slide-toggle>
        <sm-clear-filters-button
          [tableFilters]="(tableFilters$ | async)"
          (clearTableFilters)="clearTableFilters()"
        ></sm-clear-filters-button>
      </div>
    </div>
    <div class="hide-table show-table">
      <sm-experiments-table
        class="align-self-stretch"
        [selectionReachedLimit]="reachedCompareLimit"
        [selectionMode]="null"
        [reorderableColumns]="false"
        [minimizedView]="false"
        [colsOrder]="(tableColsOrder$ | async)"
        [tableCols]="tableCols$| async"
        [users]="users$ | async"
        [projects]="projects$ | async"
        [selectedExperiments]="selectedExperimentsIds | idToObjectsArray"
        [experiments]="experiments$ | async"
        [experimentTypes]="types$ | async"
        [tableFilters]="(tableFilters$ | async)"
        [tableSortFields]="(tableSortFields$ | async)"
        [tableSortOrder]="(tableSortOrder$ | async)"
        [tags]="tags$ | async"
        [hyperParamsOptions]="hyperParamsOptions$ | async"
        [activeParentsFilter]="activeParentsFilter$ | async"
        [parents]="parent$ | async"
        [noMoreExperiments]="noMoreExperiments$ | async"
        [systemTags]="systemTags$ | async"
        [entityType]="entityTypes.experiment"
        (tagsMenuOpened)="refreshTagsList()"
        (typesMenuOpened)="refreshTypesList()"
        (experimentsSelectionChanged)="experimentsSelectionChanged($event)"
        (loadMoreExperiments)="getNextExperiments()"
        (sortedChanged)="sortedChanged($event)"
        (filterChanged)="filterChanged($event)"
        (filterSearchChanged)="filterSearchChanged($event)"
        (columnResized)="resizeCol($event)"
      >
      </sm-experiments-table>
    </div>
  </div>
  <div class="buttons">
    <button type="submit"
            (click)="applyChanges()"
            class="btn btn-neon yes-button" data-id="ApplyButton">
      APPLY
    </button>
    <button (click)="closeDialog()" class="btn btn-outline-neon" data-id ="CancelButton">
      CANCEL
    </button>
  </div>

</sm-dialog-template>
