<div class="d-flex justify-content-between mb-2">
  <span class="font-weight-bold">{{titleLabel}}</span>
</div>
<sm-search
  [value]="searchText"
  [minimumChars]="0"
  [debounceTime]="0"
  class="underline-search"
  placeholder="Find scalars"
  (valueChanged)="onSearchTermChanged($event)"
></sm-search>
<div class="toggle-all-text">
  <span (click)="toggleHideAll()">{{checkedList?.length > 0 ? 'Show all' : 'Hide all'}}</span>
</div>
<sm-grouped-selectable-list
  [list]="filteredList"
  [searchTerm]="searchTerm"
  [checkedList]="checkedList"
  (onItemSelect)="itemSelect.emit($event)"
  (onItemCheck)="toggleHide($event)"
  (onGroupCheck)="toggleHideGroup($event)"
>
</sm-grouped-selectable-list>
