<ng-form #autoSelectForm='ngForm'>
  <mat-form-field [appearance]="appearance" hideRequiredMarker [class]="formFieldClass">
    <div class="d-flex-center">
      <mat-chip-grid #chipList aria-label="Fruit selection" [disabled]="disabled" [class.single]="!multiple">
        <ng-container *ngIf="multiple; else: singleChip">
          <sm-chips
          *ngFor="let item of val; trackBy: trackByFn"
          [label]="item.label"
          [allowRemove]="clearable && !disabled"
          (remove)="remove(item)"
          ></sm-chips>
        </ng-container>
        <ng-template #singleChip>
          <sm-chips
            *ngIf="val"
            [label]="val?.label"
            [allowRemove]="clearable"
            (remove)="remove()"
          ></sm-chips>
        </ng-template>
        <input
          #autocompleteInput
          matInput
          [placeholder]="!val && placeholder"
          data-id="labelInputField"
          [ngModel]="val"
          [name]="name"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [disabled]="disabled"
          (ngModelChange)="selected($event)"
          (click)="!autocomplete.panelOpen && autocomplete.openPanel()"
          class="chips-input"
        >
      </mat-chip-grid>
      <i *ngIf="!disabled"
         class="al-icon sm ps-2 me-1 ms-auto"
         data-id="filterBylabelDropdownIcon"
        [class]="val ? (multiple && clearable ? 'al-ico-dropdown-arrow' : 'al-ico-dialog-x pointer') : 'al-ico-dropdown-arrow'"
        (click)="val && !(multiple && clearable) && removeLastChip(); autocompleteInput.focus();"
        smClickStopPropagation></i>
    </div>
    <mat-autocomplete #auto="matAutocomplete"
                      [displayWith]="displayFn"
                      [class]="formFieldClass"
                      [classList]="formFieldClass"
                      autoActiveFirstOption>
      <mat-option *ngIf="filterText && isNewName && optionAddable"
                  class="item"
                  [value]="{label:filterText, value:filterText}"
                  (onSelectionChange)="customOptionAdded.emit($event)">
        "{{filterText}}"<span class="new">(Add item)</span>
      </mat-option>
      <mat-option *ngFor="let option of (filteredItems | async); trackBy: trackByFn" [value]="option">
        {{option.label}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-form>
