<span mat-menu-item *ngFor="let option of options; let i = index; trackBy: trackByValFn">
  <mat-checkbox
    class="w-100 d-flex"
    [value]="option.value"
    [checked]="checkedList?.includes(option.value) || indeterminateState[option.value] === checkboxState.exclude"
    [indeterminate]="indeterminateState[option.value] === checkboxState.exclude"
    (change)="onFilterChanged($event)"
  >
    <span
      [smTooltip]="option.tooltip || option.label"
      smShowTooltipIfEllipsis
      [showAlwaysTooltip]="!!option.tooltip"
      matTooltipPosition="above"
      data-id="FilterOption"
      class="ellipsis label"
    >{{option.label}}</span>
  </mat-checkbox>
</span>
<div *ngIf="options?.length === 0" class="empty-menu flex-middle">No item found</div>
<div *ngIf="options === null" class="flex-middle mt-5">
  <mat-spinner class="m-auto" [diameter]="32" [strokeWidth]="4" color="accent"></mat-spinner>
</div>
