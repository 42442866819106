import {Directive} from '@angular/core';

@Directive({
  selector: '[compareCardExtraHeader]'
})
export class CompareCardExtraHeaderDirective {

  constructor() {
  }

}
