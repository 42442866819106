<sm-dialog-template [header]="title">
  <div  class="data-text">
    <div class="data-text-ace" #aceEditor></div>
  </div>
  <div>
    <small *ngIf="this.showErrors"
           class="text-danger">*Not a valid json.
    </small>
  </div>
  <div class="buttons">
    <button *ngIf="!readOnly" (click)="closeDialog(false)" class="btn btn-outline-neon yes-button" data-id="cancelButton">CANCEL</button>
    <button (click)="closeDialog(!readOnly)" class="btn btn-neon" data-id="okButton">OK</button>
  </div>
</sm-dialog-template>
