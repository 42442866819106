<mat-drawer-container autosize>
  <mat-drawer opened="true" mode="side">
    <div class="list-container">
      <sm-graph-settings-bar
          class="drawer-settings-bar"
          [verticalLayout]="true"
          [smoothWeight]="settings.smoothWeight"
          [smoothType]="settings.smoothType"
          [xAxisType]="settings.xAxisType"
          [groupBy]="settings.groupBy"
          [groupByOptions]="groupByOptions"
          (changeWeight)="changeSmoothness($event)"
          (toggleSettings)="toggleSettingsBar()"
          (changeXAxisType)="changeXAxisType($event)"
          (changeGroupBy)="changeGroupBy($event)"
          (changeSmoothType)="changeSmoothType($event)"
      ></sm-graph-settings-bar>
      <sm-selectable-grouped-filter-list
          [list]="graphList"
          [checkedList]="settings.hiddenMetricsScalar"
          [searchTerm]="searchTerm$ | async"
          (itemSelect)="metricSelected($event)"
          (hiddenChanged)="hiddenListChanged($event)"
          (searchTermChanged)="searchTermChanged($event)"
      >
      </sm-selectable-grouped-filter-list>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <sm-experiment-graphs
        [metrics]="graphs"
        [hiddenList]="settings.hiddenMetricsScalar"
        [isGroupGraphs]="false"
        [isCompare]="true"
        [smoothWeight]="settings.smoothWeight"
        [smoothType]="settings.smoothType"
        [xAxisType]="settings.xAxisType"
        [groupBy]="settings.groupBy"
        [multipleSingleValueData]="singleValues"
        [hoverMode]="hoverMode$ | async"
        [exportForReport]="!modelsFeature"
        (resetGraphs)="resetMetrics()"
        (hoverModeChanged)="hoverModeChanged($event)"
        (createEmbedCode)="createEmbedCode($event)"
    >
    </sm-experiment-graphs>
  </mat-drawer-content>
</mat-drawer-container>
