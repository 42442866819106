<span class="layout"
      [ngClass]="inputClassName"
      [class.disabled]="disabled"
>
  <span
    *ngIf="!isReadonly"
    role="button"
    data-id="Select All Loaded Experiment"
    class="purple-v"
    [ngClass]="inputClassName"
    [class.checked] = "state === 'All' || state === 'Partial'"
    [class.readonly] = "isReadonly"
    [smTooltip]="getCurrentError()"
    tooltipType='validation'
    [showTooltip]="showErrors"
  >
    <i class="al-icon sm al-color white" [ngClass]="{
    'al-ico-success': state === 'All',
    spacer: state === 'None',
    'al-ico-minus': state === 'Partial'
    }">
    </i>
  </span>
  <span
    *ngIf="label"
    [class]="readonlyClassName + ' checkbox-label ellipsis'"
    [ngClass]="isReadonly ? '' : 'pointer'"
  >{{label}}
  </span>
</span>
