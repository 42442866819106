<div smClickStopPropagation class="metrics-container">
  <div *ngIf="goBack.observed" class="head">
    <i (click)="goBack.emit()" data-id="Back button" class="al-icon sm-md al-ico-back pointer m-auto" smClickStopPropagation></i>
    <h3>SELECT METRIC TO DISPLAY</h3>
  </div>


  <sm-search
    class="underline-search"
    [value]="searchText"
    [minimumChars]="0"
    [debounceTime]="0"
    placeholder="Search metric"
    (valueChanged)="searchQ($event)"
  ></sm-search>
  <div class="metrics" [class.has-title]="goBack.observed">
    <div *ngIf="!filteredMetricTree && !(searchText?.length > 0)" class="p-4 pe-none">
      <mat-spinner class="m-auto" [diameter]="32" [strokeWidth]="4" color="accent"></mat-spinner>
    </div>
    <div *ngIf="filteredMetricTree && filteredMetricTree.length === 0" class="d-flex h-100">
      <div class="empty-state">No data to show</div>
    </div>
    <mat-expansion-panel
      *ngFor="let metric of filteredMetricTree; trackBy: trackByMetric"
      [expanded]="searchText?.length > 0 || expandedMetrics[metric[1][0].metric_hash]"
    >
      <mat-expansion-panel-header
        class="py-2" expandedHeight="42px" collapsedHeight="42px"
        (click)="expandedMetrics[metric[1][0].metric_hash] = !expandedMetrics[metric[1][0].metric_hash]"
      >
        <mat-panel-title>
          <span class="panel-title" data-id="metricType">{{metric[0]}}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div class="metric-expansion-content" *ngFor="let variant of metricTree[metric[0]] | advancedFilter:searchText; trackBy: trackByVariant">
          <ng-container *ngIf="multiSelect; else single">
            <div class="variant-label">
              <sm-checkbox-control #metricVariant
                                   (formDataChanged)="toggleAllMetricsToDisplay(variant, metricVariant.formData)"
                                   [formData]="metricsCols[variant.metric_hash + variant.variant_hash] !== undefined"
                                   [label]="variant.variant"
              ></sm-checkbox-control>
            </div>
            <div *ngIf="metricVariant.formData" class="value-type">
              <sm-checkbox-control #last label="LAST"
                                   [formData]="metricsCols[variant.metric_hash + variant.variant_hash]?.includes('value')"
                                   (formDataChanged)="toggleMetricToDisplay(variant, last.formData, 'value')"
              ></sm-checkbox-control>
              <sm-checkbox-control #min label="MIN"
                                   [formData]="metricsCols[variant.metric_hash + variant.variant_hash]?.includes('min_value')"
                                   (formDataChanged)="toggleMetricToDisplay(variant, min.formData, 'min_value')"
              ></sm-checkbox-control>
              <sm-checkbox-control #max label="MAX"
                                   [formData]="metricsCols[variant.metric_hash + variant.variant_hash]?.includes('max_value')"
                                   (formDataChanged)="toggleMetricToDisplay(variant, max.formData, 'max_value')"
              ></sm-checkbox-control>
            </div>
          </ng-container>
          <ng-template #single>
            <div class="variant-label">
              <mat-radio-button
                class="sm"
                #metricVariant
                (change)="toggleAllMetricsToDisplay(variant, false)"
                [checked]="metricsCols[variant.metric_hash + variant.variant_hash] !== undefined"
              >{{variant.variant}}</mat-radio-button>
            </div>
            <div *ngIf="metricVariant.checked" class="value-type">
              <mat-radio-group
                [ngModel]="metricsCols[variant.metric_hash + variant.variant_hash][0]"
                (change)="toggleMetricToDisplay(variant, true, $event.value)"
              >
                <mat-radio-button class="sm" value="value">LAST</mat-radio-button>
                <mat-radio-button class="sm px-4" value="min_value">MIN</mat-radio-button>
                <mat-radio-button class="sm" value="max_value">MAX</mat-radio-button>
              </mat-radio-group>
            </div>
          </ng-template>
        </div>
      </ng-template>
    </mat-expansion-panel>
    <div class="more-results" *ngIf="moreResults > 0">And {{moreResults}} more, use search to narrow selection</div>
  </div>
</div>
