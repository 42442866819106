<form [class.editable]="editable" #hyperParameters="ngForm">
  <cdk-virtual-scroll-viewport #formContainer class="form-container" itemSize="58" minBufferPx="280" maxBufferPx="560">
    <ng-container *ngIf="editable">
      <div *ngFor="let parameter of formData; let index= index" class="w-100 d-flex pe-2">
        <mat-form-field
          [hintLabel]="parameterKey.invalid && parameterKey?.errors?.required? '*Required': ''"
          appearance="outline"
          class="strength"
        >
          <input
            #parameterKey="ngModel"
            #row="matInput"
            [(ngModel)]="parameter['name']"
            (keydown.enter)="nextRow($event, index)"
            placeholder="Parameter"
            name="parameterKey-{{parameter.id}}"
            matInput
            smUniqueNameValidator
            [class.highlight-text]="(searchedText?.length > 0) && parameter['name'].includes(searchedText)"
            [class.current-match]="searchIndexList[matchIndex]?.index===index && searchIndexList[matchIndex]?.col==='name'"
            [existingNames]="formNames(parameter.id)"
            required/>
          <mat-error *ngIf="parameterKey.invalid && parameterKey?.errors?.required">
            Required
          </mat-error>
          <mat-error *ngIf="parameterKey.invalid && parameterKey?.errors?.smNotAllowedStringsValidator">
            .(dot) $(dollar) and space are not allowed in parameter key.
          </mat-error>
          <mat-error *ngIf="!parameterKey?.errors?.required && parameterKey.invalid && parameterKey?.errors?.uniqueName">
            key already exists
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline"
                        class="strength">
          <input
            #parameterValue="ngModel"
            [(ngModel)]="parameter['value']"
            (keydown.enter)="nextRow($event, index)"
            name="parameterValue-{{parameter.id}}"
            placeholder="Value"
            [class.highlight-text]="(searchedText?.length > 0) && parameter['value'].includes(searchedText)"
            [class.current-match]="searchIndexList[matchIndex]?.index===index && searchIndexList[matchIndex]?.col==='value'"
            matInput/>
        </mat-form-field>
        <button class="remove-button btn" (click)="removeRow(index)">
          <i class="al-icon al-ico-trash al-color blue-400 sm-md pointer flashing-icon"></i>
        </button>
      </div>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</form>
<button *ngIf="editable" class="btn btn-dark-fill add-parameter" (click)="addRow()"><i class="fas fa-plus" data-id="AddParamterButton"></i> ADD PARAMETER</button>
<div class="table-container" *ngIf="!editable">
  <sm-table
    [columns]="cols"
    columnResizeMode="fit"
    [tableData]="formData"
    [enableTableSearch]="true"
    [globalFilterFields]="['name', 'value','description']"
    [noHeader]="true"
    [simple]="true"
    [scrollable]="true"
    [virtualScroll]="true"
    [rowHeight]="32"
    (rowClicked)="rowActivated($event)"
  >
    <ng-template pTemplate="body"
                 let-col
                 let-i="rowIndex"
                 let-row="rowData">
      <ng-container *ngIf="col.id === 'description'; else defaultRow">
        <span *ngIf="row.type!=='legacy' && (row.description || row.type)"
              class="allow-multi-space pointer"
              customClass="hyper-parameters-tooltip parameter-tooltip"
              smTooltip="{{(row.type ? ('Type: '+ row.type+'\n') : '') + (row?.description|| '')}}"
              matTooltipPosition="before"
        ><i class="al-icon al-ico-description"></i></span>
      </ng-container>
      <ng-template #defaultRow>
        <span class="ellipsis"
              [class.name]="col.id==='name'"
              smShowTooltipIfEllipsis
              matTooltipPosition="before"
              matTooltipShowDelay="250"
              [smTooltip]="row[col.id]"
        >
          <span *ngFor="let part of (searchedText ? row[col.id].split(searchedText) : [row[col.id]]); let last=last"
          >{{part}}<span class="highlight-text"
                         [class.current-match]="searchIndexList[matchIndex]?.index===i && searchIndexList[matchIndex]?.col===col.id" *ngIf="!last"
          >{{searchedText}}</span></span></span>
      </ng-template>
    </ng-template>
  </sm-table>
</div>

