<sm-dialog-template [header]="data?.header">
  <div class="model-table-container">
    <div class="w-100 model-header">
      <sm-select-model-header
        [searchActive]="true"
        [searchValue]="searchValue$ | async"
        [viewMode]="viewMode$ | async"
        [isShowArchived]="showArchive$ | async"
        [hideArchiveToggle]="data.hideShowArchived"
        [hideCreateNewButton]="true"
        [tableFilters]="tableFilters$ | async"
        (searchValueChanged)="onSearchValueChanged($event)"
        (clearFilters)="clearFilters()"
        (isArchivedChanged)="showArchives($event)"
      ></sm-select-model-header>
    </div>
    <sm-models-table
      class="model-info-container align-self-stretch"
      [selectionMode]="data.selectionMode ?? 'single'"
      [enableMultiSelect]="data.selectionMode === 'multiple'"
      [minimizedView]="false"
      [hideSelectAll]="true"
      [reorderableColumns]="false"
      [models]="models$ | async"
      [tableCols]="tableCols$ | async"
      [metadataValuesOptions]="metadataColsOptions$ | async"
      [noMoreModels]="noMoreModels$ | async"
      [users]="users$ |async"
      [frameworks]="frameworks$ |async"
      [tags]="tags$ | async"
      [projects]="(projectsOptions$ | async)"
      [systemTags]="systemTags$ | async"
      [tableSortFields]="(tableSortFields$ | async)"
      [tableSortOrder]="(tableSortOrder$ | async)"
      [tableFilters]="(tableFilters$ | async)"
      [selectedModels]="selectedModels$ | async"
      [onlyPublished]="data.selectionMode === 'single'"
      (modelsSelectionChanged)="modelsSelectionChanged($event)"
      (modelSelectionChanged)="modelSelectionChanged($event)"
      (sortedChanged)="sortedChanged($event)"
      (filterChanged)="filterChanged($event)"
      (filterSearchChanged)="filterSearchChanged($event)"
      (loadMoreModels)="getNextModels()"
    >
    </sm-models-table>
    <div class="buttons">
      <button (click)="closeDialog(null)" class="btn btn-outline-neon" data-id ="CancelButton">
        CANCEL
      </button>
      <button type="submit" *ngIf="data.selectionMode === 'multiple'"
              (click)="closeDialogMultiple()"
              class="btn btn-neon yes-button" data-id="ApplyButton">
        APPLY
      </button>
    </div>
  </div>
</sm-dialog-template>
