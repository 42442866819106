<div class="sm-menu-header">CUSTOMIZE COLUMNS</div>
<div class="list-container">
  <sm-menu-item *ngFor="let col of tableCols; trackBy: trackById"
                [itemLabel]="col.header"
                [checked]="!(col?.hidden)"
                [selectable]="true"
                [removable]="!!col.metric_hash || col.isParam || col.type==='metadata'  || col.type==='hdmd'"
                [itemValue]="col.id"
                [enableIcon]="false"
                (removeItemClicked)="removeColFromList.emit($event)"
                (itemClicked)="selectedTableColsChanged.emit(col)">
  </sm-menu-item>
</div>
<div class="p-4 pe-none" [ngClass]="{'d-none': !isLoading}">
  <mat-spinner class="m-auto" [diameter]="32" [strokeWidth]="4" color="accent"></mat-spinner>
</div>
