<div class="navbar-header-container">
  <div class="nav-bar-items-container">
    <ng-container *ngFor="let route of routes; trackBy:trackByFn">
      <sm-navbar-item
        *smCheckPermission="route.permissionCheck"
        direction="top"
        [header]="route.header"
        [active]="activeFeature === route.header"
        (click)="tabClicked(route.header)"
        [subHeader]="archivedMode && route.subHeader"
      >
      </sm-navbar-item>
    </ng-container>
  </div>
</div>
