<div class="d-flex justify-content-between">
  <div class="d-flex align-items-center">
    <sm-search
      #search
      class="light-theme"
      placeholder="Start typing model name or ID to add to your comparison"
      [value]="searchValue"
      (valueChanged)="onSearchValueChanged($event)"
      (focusout)="onSearchFocusOut()">
    </sm-search>
  </div>
  <div class="d-flex-center">
    <mat-slide-toggle
      *ngIf="!hideArchiveToggle"
      class="me-2"
      [checked]="isShowArchived"
      (change)="isArchivedChanged.emit($event.checked)"
    >Include Archived</mat-slide-toggle>
    <sm-clear-filters-button
      [tableFilters]="tableFilters"
      (clearTableFilters)="clearFilters.emit()"
    ></sm-clear-filters-button>
  </div>
</div>
