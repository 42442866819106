<sm-dialog-template [displayX]="displayX && !dialogRef.disableClose" [header]="title"
                    (xClicked)="closeDialog(undefined)" [closeOnX]="false"
                    [iconClass]="iconClass" [iconData]="iconData">
  <div *ngIf="reference" class="reference">{{reference}}</div>
  <div *ngIf="body; else templateBody" class="body" [class.text-center]="this.centerText" [innerHTML]="body | safe: 'html'"></div>
  <ng-template #templateBody>
    <ng-container *ngTemplateOutlet="this.template"></ng-container>
  </ng-template>
  <ng-content></ng-content>


  <div *ngIf="showNeverShowAgain" class="body mt-4" >
    <mat-checkbox [(ngModel)]="neverShowAgain">
      <span class="chkbx-label">Don't show this message again</span>
    </mat-checkbox>
  </div>

  <div class="buttons" *ngIf="no || yes">
    <button *ngIf="no" (click)="closeDialog(false)" id={{no}} class="btn btn-outline-neon yes-button">
      {{no}}
    </button>
    <button (click)="closeDialog(true)" id={{yes}} class="btn btn-neon" *ngIf="yes">
      {{yes}}
    </button>
  </div>

</sm-dialog-template>
