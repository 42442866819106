<div class="single-table-container" [class.dark-theme]="darkTheme">
  <div class="summary-header position-relative">Summary</div>
  <div class="d-flex items-container">
    <div class="wrapper"></div>
    <div *ngFor="let item of data" class="item">
      <div class="variant">{{item.variant}}</div>
      <div class="value">{{item.value}}</div>
    </div>
  </div>
  <div class="actions">
    <i class="al-icon al-ico-csv pointer al-color blue-300" data-id="downloadCSVButton" (click)="downloadTableAsCSV()"></i>
    <span class="csv-no-icon" (click)="downloadTableAsCSV()">CSV</span>
    <i *ngIf="createEmbedCode.observed" class="al-icon al-ico-code sm-md clickable-icon" data-id="copyEmbedCodeButton" (click)="createEmbedCodeClicked($event)"></i>
  </div>
</div>
