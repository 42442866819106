<sm-dialog-template [closeOnX]="false" (xClicked)="closeDialog(false)" header="Enqueue Experiment" iconClass="al-color blue-300 al-ico-enqueue">
  <form class="d-flex flex-column light-theme" (submit)="queueControl.valid && closeDialog(true)">
    <p *ngIf="reference">
      <b [smTooltip]="reference.length > 80 ? reference : undefined">{{reference.length > 80 ? (reference | slice:0:77) + '...' : reference }}</b>
      will be scheduled for execution through the selected queue.
    </p>

    <p class="mt-4 mb-4 text-danger enqueue-warning" *ngIf="enqueueWarning$ | async">
      <b>Note: Some experiments seems to not contain any executable code.
        <br>If enqueued to run, they might produce no results.</b>
    </p>
    <mat-form-field appearance="outline"
                    class="thin short"
                    floatLabel="always">
      <mat-label class="field-label">Queue</mat-label>
      <input
        type="text"
        #text
        data-id="Select Queue"
        tabindex="0"
        matInput
        smRequiredAutocompleteSelectionValidator
        [formControl]="queueControl"
        [matAutocomplete]="auto"
      >
      <mat-autocomplete
        class="light-theme"
        [displayWith]="displayFn"
        #auto="matAutocomplete"
      >
        <mat-option
          class="item"
          *ngIf="(userAllowedToCreateQueue$ | async) && queueControl.value && !(displayFn(queueControl.value) | stringIncludedInArray: queuesNames)"
          [value]="{name:queueControl.value}"
        >"{{displayFn(queueControl.value)}}"<span class="new">(Create New)</span></mat-option>
        <mat-option
          *ngFor="let option of filteredOptions$ | async; trackBy: trackById"
          [value]="option"
          [smTooltip]="option.name" smShowTooltipIfEllipsis
        >
          <span class="pointer-events-none"
            *ngFor="let part of (text.value ? split(option.name, text.value) : [[option.name, text.value]]); let last=last"
          >{{part[0]}}<b *ngIf="!last">{{part[1]}}</b></span>
        </mat-option>
      </mat-autocomplete>
      <mat-error
        *ngIf="queueControl.hasError('requiredAutocompleteSelection') || queueControl.hasError('required')">
        Please provide a value
      </mat-error>
    </mat-form-field>

  </form>
  <div class="buttons">
    <button (click)="closeDialog(false)" class="btn btn-outline-neon" data-id ="CancelButton">
      CANCEL
    </button>
    <button  (click)="closeDialog(true)" class="btn btn-neon yes-button" [disabled]="!queueControl.valid" data-id="EnqueueButton">
      ENQUEUE
    </button>
  </div>
</sm-dialog-template>
