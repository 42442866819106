import {Directive} from '@angular/core';

@Directive({
  selector: '[compareCardBody]'
})
export class CompareCardBodyDirective {

  constructor() {
  }

}
